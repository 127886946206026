//倒计时
export default function countDown(maxtime, callback1, callback2) {
  return function () {
    if (maxtime >= 0) {
      let minutes = Math.floor(maxtime / 60)
      let seconds = Math.floor(maxtime % 60)
      minutes = minutes >= 10 ? minutes : `0${minutes}`
      seconds = seconds >= 10 ? seconds : `0${seconds}`
      let minutesPlace = minutes > 0 ? minutes + 'min ' : '  '
      let secondsPlace = seconds > 0 ? seconds + 'sec' : '00sec'
      let msg = minutesPlace + secondsPlace
      callback1.apply(this, [msg])
      --maxtime
    } else {
      callback2.apply(this)
    }
  }
}
