<template>
  <!-- 扫码支付页面 -->
  <div class="content_box">
    <div class="inner_max">
      <h4>Hong Kong QR Bank Transfer</h4>
      <div class="content infoDesc">
        <div>
          <p class="bill-title">Bill Reference Number</p>
          <p class="bill-value">{{ orderNum }}</p>
        </div>
        <div>
          <p class="amount-title">Amount</p>
          <p class="amount-value">
            <span v-if="accountCurrency === 'USD'">{{ operateAmount }} USD&nbsp;&nbsp;|</span>
            <span v-if="accountCurrency === 'USC'">{{ operateAmount }} USC&nbsp;&nbsp;|</span>
            <span class="amount-number-blod"
              >&nbsp;&nbsp;{{ accountCurrency === 'HKD' ? operateAmount : depositAmount }}</span
            >
            HKD
          </p>
        </div>
      </div>
      <div class="QR-box">
        <div></div>
        <div class="QR-info">
          <p>Scan Below QR Using Your Banking Application</p>
          <p class="QR-info-amount">
            <span class="QR-info-amount-number">{{ accountCurrency === 'HKD' ? operateAmount : depositAmount }}</span
            >HKD
          </p>
        </div>
        <div class="QR-main">
          <div class="QR-img">
            <div class="QR-img-QRcode">
              <div class="QR-img-QRcode-mark" v-if="countDownEnd" @click="refreshQRcode()">
                <div>
                  <p><i class="el-icon-refresh-right"></i></p>
                  <p>Refresh</p>
                </div>
              </div>
              <img width="100%" height="100%" :src="QRcode" alt="" />
            </div>

            <div class="fps">
              <img width="40" height="40" :src="fps" alt="" />
            </div>
            <p v-show="QRodePeriod" class="countDown"><i class="el-icon-time"></i>&nbsp;&nbsp;{{ QRodePeriod }}</p>
          </div>
          <div class="QR-description">
            <div>
              <p class="desc">1</p>
              <p>Please use a mobile payment app that supports FPS</p>
            </div>
            <div>
              <p class="desc">2</p>
              <p>
                Scan the QR code, review the transaction details and confirm the payment instruction
                <br />
                <span class="tip"
                  >If you are usingmobile device, you may simply screen capture the QR code and upload the image to the
                  payment app to complete the payment</span
                >
              </p>
            </div>
            <div>
              <p class="desc">3</p>
              <p>After the payment is done, you will see a payment acknowledgement on your app.</p>
            </div>
          </div>
        </div>
        <div class="QR-warning">
          <p>1. The QR code is for one-time use only.</p>
          <p>2. Please complete the transaction with 10 minutes,else the QR code will expire.</p>
          <p>3. We do not accept third-party payment, third party payment will be declined</p>
        </div>
        <div class="QR-okBtn"><el-button @click="scanDone()">Payment Completed</el-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/page/deposit';
import countDown from '@/util/countDown.js';
import { apiDollarSmartQR_payment, apiDollarSmartQRcode_confirmation } from '@/resource';
import fps from '@/assets/images/fps.png';
//阻止页面回退
// import preventBack from 'vue-prevent-browser-back';

export default {
  name: 'HongKongQR-scanPay',
  mixins: [mixin],
  // mixins: [mixin, preventBack],
  data() {
    return {
      fps,
      //二维码有效期10分钟
      QRodePeriod: '',
      QRcode: '',
      orderNum: '',
      countDownEnd: false,
      depositAmount: 0,
      operateAmount: 0,
      targetCurrency: 'HKD',
      accountCurrency: ''
    };
  },
  methods: {
    async refreshQRcode() {
      await this.fetchToken();
      apiDollarSmartQR_payment(JSON.parse(sessionStorage.getItem('dollarSmartQRParams')).formArgs, this.token).then(
        result => {
          if (result.data.code == 0) {
            this.QRcode = result.data.data.QRBase64;
            this.orderNum = result.data.data.orderId;
            this.countDownEnd = false;
            this.countDownBegin();
          }
        }
      );
    },
    scanDone() {
      apiDollarSmartQRcode_confirmation(
        {
          virtualNumber: this.orderNum
        },
        this.token
      ).then(result => {
        if (result.data.code === 0 && result.data.data === true) {
          this.$router.push(`/thirdPartyDepositResult/dollarsmartQR?ref=${result.data.msg}`);
        } else {
          this.$router.push('/thirdPartyDepositFailed/dollarsmart');
        }
      });
    },
    countDownBegin() {
      let timer = setInterval(
        countDown(
          10 * 60,
          function (...args) {
            this.QRodePeriod = args[0];
          },
          function () {
            this.countDownEnd = true;
            this.QRodePeriod = '';
            clearInterval(timer);
          }
        ).bind(this),
        1000
      );
    }
  },
  mounted() {
    let dollarSmartQRParams = JSON.parse(sessionStorage.getItem('dollarSmartQRParams'));
    this.QRcode = dollarSmartQRParams.data.QRBase64;
    this.orderNum = dollarSmartQRParams.data.orderId;
    this.accountCurrency = dollarSmartQRParams.accountCurrency;
    this.depositAmount = dollarSmartQRParams.formArgs.depositAmount;
    this.operateAmount = dollarSmartQRParams.formArgs.operateAmount;
    this.countDownBegin();
  }
};
</script>

<style lang="scss" scoped>
$boxBackColor: #151827;
$boxWidth: 100%;
.infoDesc {
  width: $boxWidth;
  // height: 20px;
  background-color: $boxBackColor;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;

  .bill-value,
  .amount-value {
    margin-top: 25px;
  }
  .bill-title,
  .amount-title {
    font-weight: 600;
  }
  .amount-title {
    text-align: right;
  }
  .amount-number-blod {
    color: #e35728;
    font-size: 16px;
  }
}
.QR-box {
  width: $boxWidth;
  background-color: $boxBackColor;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 50px 200px;
  // taxt
  .QR-info,
  .QR-img,
  .QR-description {
    margin-top: 30px;
  }
  .QR-info {
    text-align: center;
    .QR-info-amount {
      margin-top: 20px;
      .QR-info-amount-number {
        font-size: 18px;
        color: #f00;
        padding-right: 5px;
      }
    }
  }
  .QR-main {
    // width: 300px;
    display: flex;
    justify-content: space-between;
  }
  .QR-img {
    // justify-content: space-between;
    .QR-img-QRcode {
      width: 250px;
      height: 250px;
      position: relative;
    }
    .QR-img-QRcode-mark {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(#000, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
    }
    .fps,
    .countDown {
      text-align: center;
      margin-top: 5px;
      height: 15px;
    }
    .fps {
      height: 40px;
    }
  }
  .QR-description {
    width: 400px;
  }
  .QR-description div {
    display: flex;
    vertical-align: middle;
    p:first-child {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background-color: #ff174c;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 20px;
    }
    p:last-child {
      line-height: 25px;
      margin-top: 3px;
    }
    .tip {
      color: #42767f;
    }
  }
  .QR-description div:nth-child(2),
  .QR-description div:nth-child(3) {
    margin-top: 50px;
  }
  .QR-warning {
    border: 1px solid #292c3d;
    color: #e35728;
    font-size: 14px;
    margin-top: 50px;
    padding: 10px 10px;
  }
  .QR-okBtn {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
